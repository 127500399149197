.maze-area{
  text-align: center;
}

.btn-default{
  font-size: large;
  padding: .7em;
  width: 10em;
  border-radius: 1em;
  border: .5em solid white;
  color: white;
  font-weight: 1000;
  background-color: black;
  transition:all .1s;
}

.btn-collapse{
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  color: rgb(63, 63, 63);
  text-decoration: underline;
  font-weight: 1000;
  background: none;
  transition:all .1s;
}

.hide{
  display: none;
}

.btn-default:disabled{
  background-color: rgb(138, 138, 138);
}

.btn-default:enabled:hover{
  cursor: pointer;
  background-color: rgb(45, 45, 45);
}
.btn-reset{
  font-size: large;
  padding: .7em;
  width: 10em;
  border-radius: 1em;
  border: .5em solid white;
  color: white;
  font-weight: 1000;
  background-color: rgb(255, 0, 0);
  transition:all .1s;
}

.btn-reset:disabled{
  background-color: rgb(255, 121, 121);
}

.btn-reset:enabled:hover{
  cursor: pointer;
  background-color: rgb(212, 0, 0);
}


#maze-canvas{
  border: 5px solid rgb(67, 67, 67);
}

#github-icon{
  
  position: absolute;
  right: .5em;
  bottom: .5em;
  width: 1.5em;
}